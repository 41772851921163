import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

import SEO from "../components/seo"
import classes from "./pliki.module.scss"

const Pliki = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiPlikiDoPobrania {
        edges {
          node {
            nazwa_pliku
            plik {
              publicURL
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Do pobrania" />
      <h1 className={classes.header}>Pliki do pobrania</h1>
      <ul className={classes.filesList}>
        {data.allStrapiPlikiDoPobrania.edges.map(file => (
          <li className={classes.filesListItem}>
            {file.node.nazwa_pliku}
            <a
              key={file.node.nazwa_pliku}
              href={file.node.plik.publicURL}
              download={file.node.nazwa_pliku}
              className={classes.file}
              target="_blank"
              rel="noreferrer"
            >
              <p>Pobierz</p>
            </a>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default Pliki
